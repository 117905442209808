import { categoryWeights } from "./configResults";

export const numberRounder = (number, decPlace) => {
  return (Math.round(number * 10) / 10).toFixed(decPlace);
};

export const sumCategoryScores = (categoryArray) => {
  let categoryScores = [];
  Object.keys(categoryArray).forEach((key) => {
    categoryScores[key] = roundToFixed(
      categoryArray[key].reduce((partialSum, a) => partialSum + a, 0),
      2
    );
  });

  return categoryScores;
};

export const checkIfTypeUndefined = (parameter) => {
  if (typeof parameter === "undefined") {
    return " ";
  } else {
    return parameter;
  }
};

export const schemaBuilder = (
  assessmentID,
  personalDetails,
  totalScore,
  responses,
  categoryArray,
  categoryObject,
  categoryObjectUnWeighted
) => {
  let categoryScores = sumCategoryScores(categoryArray);
  const now = new Date();
  const submissionDate = now.toISOString();

  let jobTitle = checkIfTypeUndefined(personalDetails.JobTitle);

  //Divide total score by num of categories
  let overallScore = totalScore / 5;

  const schema = {
    assessmentID: { S: assessmentID },
    firstName: { S: personalDetails.FirstName },
    lastname: { S: personalDetails.LastName },
    companyName: { S: personalDetails.CompanyName },
    jobTitle: { S: jobTitle },
    overallScore: {
      N: numberRounder(overallScore, 2).toString(),
    },
    categoryObject: { S: JSON.stringify(categoryObject) },
    categoryObjectUnWeighted: { S: JSON.stringify(categoryObjectUnWeighted) },
    submissionDate: { S: submissionDate },
  };
  for (const [key, value] of Object.entries(responses)) {
    schema[key] = { S: value };
  }
  for (const [key, value] of Object.entries(categoryScores)) {
    schema[key] = { N: value.toString() };
  }
  return schema;
};

export function sumCategoryValues(categoryObject) {
  const result = [];

  for (const category in categoryObject) {
    const sum = categoryObject[category].reduce((acc, val) => acc + val, 0);
    result.push(sum * categoryWeights[category]);
  }

  let sum2 = result.reduce((acc, val) => acc + val, 0);
  sum2 = (sum2 / 4) * 100;
  return sum2;
}

export function sumSeparateCategories(categoryObject) {
  const result = {};
  for (const category in categoryObject) {
    const sum = categoryObject[category].reduce((acc, val) => acc + val, 0);
    result[category] = numberRounder((sum / 5) * 100, 0);
  }

  return result;
}

//Prevent function from executing multiple times
export function debounce(fn, ms) {
  let timer;
  return (_) => {
    clearTimeout(timer);
    timer = setTimeout((_) => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

export const findOverallLevelRanking = (score) => {
  const ranking = {
    Beginner: { low: 0, high: 24 },
    Intermediate: { low: 25, high: 49 },
    Advanced: { low: 50, high: 74 },
    Expert: { low: 75, high: 100 },
  };

  for (const [key, value] of Object.entries(ranking)) {
    if (
      numberRounder(score, 0) >= value.low &&
      numberRounder(score, 0) <= value.high
    ) {
      return key;
      //set state value for this level
    }
  }
};

export function roundToFixed(number, decimalPlaces) {
  const multiplier = 10 ** decimalPlaces;
  return Math.round(number * multiplier) / multiplier;
}

//Sums the weights of each question for every category
export function sumCategoryWeights(surveyQuestions) {
  const categoryWeights = {};

  for (const question in surveyQuestions) {
    const { category, weight } = surveyQuestions[question];
    if (category in categoryWeights) {
      categoryWeights[category] += weight;
    } else {
      categoryWeights[category] = weight;
    }
  }

  // Round the weights to 4 decimal places (adjust this as needed)
  for (const category in categoryWeights) {
    categoryWeights[category] = roundToFixed(categoryWeights[category], 4);
  }

  return categoryWeights;
}
