import "./survey.scss";

const Header = () => {
  const s10Logo =
    "https://station10.co.uk/wp-content/uploads/2021/02/station10-logo-white.svg";
  
  return (
    <>
      <div className="surveyContainer">
        <h1>Data Capability Assessment</h1>
        <div className="logoContainer">
          <p>by</p>
          <img className="s10logo" src={s10Logo} alt="station10 logo"></img>
 
        </div>
      </div>
    </>
  );
};

export default Header;
