import SurveyQuestions from "./SurveyQuestions";
import * as React from "react";
import { useState } from "react";
import "./intro.scss";


const Intro = () => {
  const [isReady, setIsReady] = useState(false);

  return (
    <>
      {isReady === false && (
        <div className="introText">
          <h2>Jonesco Assessment</h2>
          <p className="introMessage">Thank you for taking the time to complete this Data Capability Assessment.  This will help identify your current level of data sophistication, across a range of different factors.  On the back of this, we will work with you to identify next steps and recommendations on your data maturity journey.  The assessment should take between 10-15 minutes to complete.</p>
         
          <button
            onClick={() => {
              setIsReady(true);
            }}
          >
            START
          </button>
          </div>
)}
       
      {isReady === true && <SurveyQuestions />}
    </>
  );
};

export default Intro;
