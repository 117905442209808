import * as React from "react";
import "./confirmationPage.scss";
const ConfirmationPage = props => {
  return (
    <>
      <section className="confirmationPageContainer">
        <h1 className="confirmationPageHeading">You have successfully submitted your results!</h1>
        <div> <p className="textalignCenter">
        Thank you for completing the assessment.  We will take your answers, together with those from other areas of your business, and incorporate them into the upcoming workshop on recommendations for your data programme.
          In the meantime, if you have any questions, please get in touch with the Station10 team on {" "}
          <a href="mailto:assessment@station10.co.uk">assessment@station10.co.uk</a> 
        </p></div>
       
      </section>
    </>
  );
};

export default ConfirmationPage;
