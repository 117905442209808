export const AWSconfig = {
  identityPoolId: process.env.REACT_APP_COGNITO_IDENTITYPOOLID,
  region: "eu-west-1",
  dynamoDBApiVersion: "2012-08-10",
};

// station10_password;

// station10_user;

export const freeTextQuestion = "freeTextQuestion";

export const surveyQuestions = {
  q1: {
    name: "dataCultureQ1",
    title: "How Data Literate, or Data Informed, is the business?",
    weight: 0.225,
    choice1:
      "We don't use data, and the senior team encourage teams to follow their instinct, so we don’t see any value in becoming more literate.",
    choice2:
      "There are small teams who understand the specific datasets that they use on a daily basis, but no-one outside of those teams will ask about them.  Senior management either never ask questions about data, or when they do they have unreasonable expectations of what can be answered, and how quickly.",
    choice3:
      "Each department will understand and discuss its key metrics, and may have clear definitions, but this is not shared outside each department.  Senior management have an understanding that data should inform decisions, but there is a sense that they don't always know what questions can be answered across the business.",
    choice4:
      "There is a good understanding of different datasets across the business, and there is a clear set of definitions that people can refer to.  Senior management generally ask for data to inform key strategic decisions, but often need to be guided through what it really means.",
    choice5:
      "The entire business, including senior management, understand and use data for both tactical and strategic decisions.  There are clear processes for sharing and communicating data definitions, and teams are regularly trained on how to use data across the organisation.",
    category: "category1",
  },
  q2: {
    name: "dataCultureQ2",
    title: "How is data represented, if at all, on the board?",
    weight: 0.225,
    choice1: "We have no data representation at board level",
    choice2:
      "If we have a large, business-critical data-related project, it would be represented at board level by the relevant person accountable for delivery, but not on an ongoing basis.",
    choice3:
      "Each board member will have data activities in their teams, so they will report on data activities, but this will be part of their overall departmental responsibilities, not separated in any way.",
    choice4:
      "We have an active data organisation that is managed as part of the wider remit and responsibilities of another board member (eg CTO, or CFO).  Any data accountability goes through them and is actively reported on, but data doesn't have its own separate representation on the board.",
    choice5:
      "We have a dedicated board member (Chief Data Officer or equivalent) who is accountable for managing the whole data organisation, and is responsible for delivering and demonstrating business value from the data operations.",
    category: "category1",
  },
  q3: {
    name: "dataCultureQ3",
    title:
      "Are there specific data roles in the business (eg Data Steward, Data Evangelist, Data Architect, Data Analyst, etc)",
    weight: 0.2,
    choice1:
      "We don't have specific roles for data, and I'm not sure we really need them",
    choice2:
      "We have basic data management roles, mainly across data cleaning, but they are not dedicated or formalised",
    choice3:
      "We have some formal roles in data management and reporting, but we don't have steward roles outside of IT",
    choice4:
      "We have a range of data specific roles with specific accountability for data across several departments, both for BAU and strategic support activities",
    choice5: `We have a range of data "super-users" embedded in every part of the business, who advocate and support data as a business asset to inform better decision-making`,
    category: "category1",
  },
  q4: {
    name: "dataCultureQ4",
    title:
      "How much knowledge sharing takes place between teams and individuals in relation to data analysis activities?",
    weight: 0.175,
    choice1:
      "We have limited pockets of analysis, so there is no knowledge to be shared.",
    choice2:
      "Knowledge and methodologies aren't formally managed, but they might be shared informally.",
    choice3:
      "Analysts share their methods and outcomes with an established internal community of other analysts",
    choice4:
      "There is an active community that extends outside of the analyst groups into other adjacent areas of the business, but not all",
    choice5:
      "Sponsored by and engaged in by the senior management, we have an established process of insight sharing, documentation and post-project reviews, including the creation of success stories, which are then shared across the business.  ",
    category: "category1",
  },
  q5: {
    name: "dataCultureQ5",
    title:
      "How well understood upfront is the value for data to deliver to the business?",
    weight: 0.175,
    choice1:
      "Data isn't used in a concerted way, so it doesn't have a value associated with it",
    choice2:
      "The value of data is recorded in an ad-hoc way on specific projects, in retrospect, if we have identified value, but this is not linked to other tactical or strategic projects.",
    choice3:
      "Value of data is identified on a project basis, by those managers who are interested in it.",
    choice4:
      "Data initiatives are supported by a business case for impact upfront, but this is not always validated afterwards",
    choice5:
      "All data projects are checked against the business case to demonstrate the impact on the business over the lifecycle of the value generation period, and may be reassessed and amended if the results are different to expectations",
    category: "category1",
  },
  q6: {
    name: "dataDrivenMarketingQ1",
    title:
      "Do you measure marketing KPIs?  If so, how do you set your metrics?",
    weight: 0.2,
    choice1:
      "We don't measure marketing performance or create any other marketing-specific data-supported metrics",
    choice2:
      "We don't provide marketing performance KPIs regularly, but we can report on them if necessary",
    choice3:
      "We have patchy measurement around core marketing data, but they are not always trusted",
    choice4:
      "We have metrics and ongoing marketing assessments in place for KPIs deemed important by senior management",
    choice5:
      "We have marketing reporting metrics which are monitored as a key BAU activity, and how these contribute and impact other areas of the business is fully understood across the organisation",
    category: "category2",
  },
  q7: {
    name: "dataDrivenMarketingQ2",
    title:
      "How comprehensive is the data you use across the entire customer journey?",
    weight: 0.175,
    choice1:
      "We don't record information about the customer journey to purchase",
    choice2:
      "Some parts of the core steps of the journey are recorded, but are analysed on an ad-hoc basis",
    choice3:
      "We have a good understanding of the core customer journey, and monitor this regularly, but how this relates to broader areas of the journey is not clear",
    choice4:
      "We know what the full multichannel customer journey should be, but we struggle to record and measure all parts of it",
    choice5:
      "We have an active measurement process across the entire multichannel journey, and proactively use this information to drive insights and target segments that we identify",
    category: "category2",
  },
  q8: {
    name: "dataDrivenMarketingQ3",
    title:
      "How well understood are the activities that you do across the conversion funnel?",
    weight: 0.175,
    choice1:
      "We have no concept of a customer conversion funnel and don't record the data in a way that could inform one",
    choice2:
      "There's no comprehensive or reliable way of recording or visualising the conversion funnel, but we do have data that could inform it if needed",
    choice3:
      "We have a set of reports to understand the conversion funnel, but the sales and marketing teams don't have the capacity to take learnings from them",
    choice4:
      "We have a weighted pipeline and KPIs which is well understood by core teams, but not across the business, and we don't habitually take learnings from this to improve progress through the funnel",
    choice5:
      "We proactively assess and analyse customer progress through the funnel, and use predictive techniques to identify any blockers or risks, and take defined steps to address these",
    category: "category2",
  },
  q9: {
    name: "dataDrivenMarketingQ4",
    title:
      "What is the capability for data analytics in the sales and marketing teams?",
    weight: 0.15,
    choice1:
      "We have no analytics capability, other than spreadsheets in particular teams",
    choice2:
      "Data isn't easily analysed to support decision-making, whether tactical or strategic",
    choice3: "We have a few analysts who work on one or two datasets",
    choice4:
      "We have a business intelligence function in place which delivers decision support for more than one department",
    choice5:
      "We have invested in predictive analytics skills and technology to support our most critical activities",
    category: "category2",
  },
  q10: {
    name: "dataDrivenMarketingQ5",
    title: `How "actionable" are the insights or activities on the back of data analytics?`,
    weight: 0.15,
    choice1: "We don't use insights to drive any marketing decision-making",
    choice2:
      "We use basic insights to inform tactical decisions in marketing, but the data isn't trusted for more than that level",
    choice3:
      "We have an insight function that produces reports but they are often overstretched and can't deliver on all requests, so the actions are not always followed up fully",
    choice4:
      "Both BAU and strategic plans are informed by insights, but these are not always used by every team.",
    choice5: `The data function has an impact "target" that it is due to deliver to the business, therefore, all insights produced are required to have clear next steps on how to improve performance, and this is understood across all teams.`,
    category: "category2",
  },
  q11: {
    name: "dataDrivenMarketingQ6",
    title:
      "Are marketing team members empowered to follow test and learn techniques to deliver improved performance over time?",
    weight: 0.15,
    choice1:
      "We trust in our instincts as experts in our business, so we don't see the need for a testing and learning approach",
    choice2:
      "We have tried a few test and learn approaches, but they never delivered enough value, so we stopped using them",
    choice3:
      "Core marketing activities use testing and learning approaches and have funding and resources to support them, but how they deliver value is not systematically recorded and understood",
    choice4:
      "We have an active marketing optimisation programme, which delivers value, but we have not scaled this to all areas of marketing",
    choice5:
      "Every area of our operations is always looking to improve, and we have ongoing testing programmes, and testing and learning is embedded into our development and marketing production activities as standard.",
    category: "category2",
  },
  q12: {
    name: "marketingDataAssestsQ1",
    title: "How is data visualised and presented in the business?",
    weight: 0.2,
    choice1:
      "Data is not presented in any consistent way.  We don't have a link between what we do as a business and the data that may support it.",
    choice2:
      "Data is available in technical ways to support operations and day-to-day systems.  It is rarely shared outside of IT, so there is little need for visualisation.",
    choice3:
      "Data is used across a range of models and reports in data teams, and we have a reporting tool, but the reports and the language around data are not shared widely across the business ",
    choice4:
      "We have a consistent and well-documented set of visualisation tools and clear set of definitions, which are shared across most teams.",
    choice5:
      "Advanced multi-touch attribution models with continuous optimisation for marketing performance",
    category: "category3",
  },
  q13: {
    name: "marketingDataAssestsQ2",
    title:
      "Is there a single version of the truth or primary data source for marketing data?",
    weight: 0.25,
    choice1:
      "No.  We have no idea how many copies of datasets there are, or what they are used for.",
    choice2:
      "There are a couple of data sources, which don't really reconcile, and are not managed by robust data governance processes",
    choice3:
      "We have a basic single version of the truth for core datasets, but there are copies which we know about",
    choice4:
      "We have a single version of the truth, which is managed in line with our data models and data governance activity",
    choice5:
      "We have a single version of the truth, which is linked to the master data model, and supported by rigorous data processes to ensure accuracy",
    category: "category3",
  },
  q14: {
    name: "marketingDataAssestsQ3",
    title: "What does the data architecture do in your marketing organisation?",
    weight: 0.15,
    choice1:
      "We have no vision or structure around our marketing data architecture",
    choice2: `We have no formal "marchitecture" but we follow some basic principles of data integration across systems`,
    choice3:
      "We have a function for data architecture, but it is not fully resourced and doesn't have a remit to make significant changes",
    choice4:
      "There is a defined marketing data architecture and strategy which is understood, but not across all disciplines.",
    choice5:
      "Data architecture is part of our wider strategic marketing data framework and plays a major part in how we operate and evolve the business",
    category: "category3",
  },
  q15: {
    name: "marketingDataAssestsQ4",
    title:
      "What costs and value is understood from commercial data, and the outputs derived from that data?",
    weight: 0.175,
    choice1:
      "The value of the outputs from data is unknown - either in terms of who benefits in the business, nor the scale of the value",
    choice2:
      "Data is understood to be a source for outputs that have value, but we don't know what the overall costs are",
    choice3:
      "Data is valued as a source in some key areas (particularly around internal focused activities) and the costs are understood",
    choice4:
      "Data outputs and the value derived from them is of good quality and meets expectations",
    choice5:
      "The outputs of data are intrinsic to how we run our most valuable services, and the value of data is well understood across the business",
    category: "category3",
  },
  q16: {
    name: "marketingDataAssestsQ5",
    title: "Are data investment proposals sponsored at board level?",
    weight: 0.225,
    choice1: "We have no data investment sponsored at board level",
    choice2:
      "We have some initiatives that are normally related to big problems we are trying to address.  They can get priority if they are seen as important",
    choice3:
      "Senior management are aware of the value of data investment, but support is project-based",
    choice4:
      "Some data investment initiatives are supported at a board level, but often not for the entire project lifecycle",
    choice5:
      "All data investment projects are sponsored by the board and are given support for the resources to deliver them",
    category: "category3",
  },
  q17: {
    name: "dataCollectionQ1",
    title: "How clear are the purposes for which you collect data?",
    weight: 0.2,
    choice1:
      "We collect data for no obvious reason, other than `we have always done this`",
    choice2:
      "We do collect data we don't use more than once.  We don't know if we might break something if we stop collecting it.",
    choice3:
      "We understand our primary data collection purposes, although there is some confusion as to why some data is collected.",
    choice4: "We use data governance and reviews to identify unneeded data",
    choice5:
      "We regularly review our data collection activities in line with our business needs.  Data collection activities are managed on the back of these reviews",
    category: "category4",
  },
  q18: {
    name: "dataCollectionQ2",
    title: "How well established is your first party data strategy?",
    weight: 0.1,
    choice1: "We don't really see the need for a first party data strategy.",
    choice2:
      "We are aware of first party data trends, but haven't done anything consciously about it yet.",
    choice3:
      "We have identified which areas need to move to a first party environment, and have started the migration process, but have yet to see large amounts of value.",
    choice4:
      "All core data sets have been moved to first party data, we have a clear understanding of what value this generates, but this is not widely understood across all relevant areas of the marketing teams.",
    choice5:
      "All areas of marketing and sales understand the impact of first party data and what processes and activities are changing to facilitate this.",
    category: "category4",
  },
  q19: {
    name: "dataCollectionQ3",
    title:
      "How do you use data to partner with other organisations to manage marketing activities?",
    weight: 0.2,
    choice1:
      "We don't share data with other organisations for marketing in any way, and I don't see the need to do so",
    choice2:
      "We may share some of our marketing data on a particular campaign if it is deemed necessary, but it would be done on an ad-hoc basis, and we don't have the systems to do this more regularly",
    choice3:
      "We share marketing and behaviour data on some of our campaigns, but it takes a lot of effort to pull this together",
    choice4:
      "Our first party data information is well organised and we regularly use data to inform our marketing campaigns, but we generally use their systems to do the data transfers",
    choice5:
      "First party data sharing is a key component of our marketing activities, and we use a data clean room or data platforms to share this data so we can make active decisions",
    category: "category4",
  },
  q20: {
    name: "dataCollectionQ4",
    title:
      "How is data accountability and ownership managed across the commercial/marketing side of the business?",
    weight: 0.15,
    choice1: "Our datasets have no clear accountability for ownership",
    choice2:
      "Responsibility for data is held informally by those who are interested in it",
    choice3:
      "Core commercial & marketing datasets are owned jointly by those who are accountable for it, and those who manage it.  Non-core data has no clear accountability.",
    choice4:
      "Core commercial datasets have clear and well-understood accountability, while non-core data sits with individual teams, where accountability is agreed amongst those teams",
    choice5:
      "There is a senior management sponsor for all data assets in the business, including commercial.  Data asset ownership is embedded across the organisation, and reports back to the relevant senior sponsor.",
    category: "category4",
  },
  q21: {
    name: "marketingDataGovernanceQ1",
    title: "How well is data quality managed?",
    weight: 0.2,
    choice1:
      "Data is of variable quality, and it's impossible to measure the quality",
    choice2:
      "We don't clean data enough, so we spend a lot of time with manual tasks to get it ready for analysis",
    choice3:
      "Our core data is of good enough quality, but we have to work hard to keep it like that and we struggle to improve some of the key metrics associated with quality",
    choice4:
      "We have created and maintain quality metrics for most of our data, and we try and automate some of the maintenance processes",
    choice5:
      "We ensure our data is maintained to the agreed levels of quality, and everyone understands the metrics",
    category: "category5",
  },
  q22: {
    name: "marketingDataGovernanceQ2",
    title: "Do you measure data quality?  If so, how do you set your metrics?",
    weight: 0.15,
    choice1: "We don't measure data quality at all",
    choice2:
      "We don't provide data quality metrics on a regular basis, but can give them on a project basis if needed",
    choice3:
      "We have limited measurement around the core datasets, but not more broadly, and the metrics are not always trusted",
    choice4:
      "We have measurement and data quality assessments in place for datasets that are identified as important",
    choice5:
      "There is an organisational-wide data quality framework, and these are monitored on an ongoing basis and reported to the board",
    category: "category5",
  },
  q23: {
    name: "marketingDataGovernanceQ3",
    title: "Data privacy management",
    weight: 0.1,
    choice1:
      "We collect data on customer behaviour whether or not the customer provides consent",
    choice2:
      "We have a data privacy policy, but we don't really check what it includes, and we don't ensure that the data captured matches to what the policy says",
    choice3:
      "We have a thorough data privacy policy and we ensure with regular validation that the data capture and usage complies with it",
    choice4:
      "We have a thorough data privacy policy and associated activities (such as data deletion), and ensure all end users of customer data understand how any data can be used.",
    choice5:
      "We follow a privacy by design process, and ensure all customer data, consent and the ethics of how it is used is incorporated into business processes from inception to erasure",
    category: "category5",
  },
  q24: {
    name: "marketingDataGovernanceQ4",
    title: "How secure is the data and how is that security maintained?",
    weight: 0.15,
    choice1:
      "We don't know if the data is secure, and I wouldn't know how to go about finding out",
    choice2:
      "We don't use a risk management framework to identify and support known risks in our datasets",
    choice3:
      "We have an established security framework for our core data, and have that internally audited",
    choice4:
      "Our data is secure and aligned to the risk appetite of the business, and are regularly externally audited",
    choice5:
      "All data is secure as outlined in our framework, and we run additional sweeps and ethical hacking programmes so we are audited to the highest standards",
    category: "category5",
  },
};

const createSurveyQuestions = () => {
  const pages = [];
  pages.push({
    name: "PersonalDetails",
    elements: [
      {
        type: "text",
        name: "FirstName",
        isRequired: true,
        title: "Enter your first name",
        hideNumber: true,
      },
      {
        type: "text",
        name: "LastName",
        isRequired: true,
        title: "Enter your last name",
        hideNumber: true,
      },
      {
        type: "text",
        name: "CompanyName",
        isRequired: true,
        title: "What company do you work for?",
        hideNumber: true,
      },
      {
        type: "text",
        name: "JobTitle",
        title: "What is your job title?",
        hideNumber: true,
      },
    ],
  });
  // const surveyArray = [];
  for (let i = 1; i <= 24; i++) {
    //for (let i = 1; i <= 1; i++) {
    // Create a new object for each iteration
    const surveyQuestion = {
      elements: [
        {
          type: "radiogroup",
          name: surveyQuestions["q" + i].name,
          isRequired: true,
          category: surveyQuestions["q" + i].category,
          title: surveyQuestions["q" + i].title,
          choices: [
            {
              value: surveyQuestions["q" + i].choice1,
              score: 0,
              weight: surveyQuestions["q" + i].weight,
            },
            {
              value: surveyQuestions["q" + i].choice2,
              score: 1,
              weight: surveyQuestions["q" + i].weight,
            },
            {
              value: surveyQuestions["q" + i].choice3,
              score: 2,
              weight: surveyQuestions["q" + i].weight,
            },
            {
              value: surveyQuestions["q" + i].choice4,
              score: 3,
              weight: surveyQuestions["q" + i].weight,
            },
            {
              value: surveyQuestions["q" + i].choice5,
              score: 4,
              weight: surveyQuestions["q" + i].weight,
            },
          ],
        },
      ],
    };
    pages.push(surveyQuestion);
  }
  return { pages };
};

export const surveyJson = createSurveyQuestions();
